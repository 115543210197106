/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from "react";
import "./AdminCandidateProfile.css";
import search from "../../../../assests/search.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import Pagination from "./Pagination";
import { storeAction } from "../../../../Store/Store";
import moment from "moment/moment";

const AdminCandidateProfile = ({
  alldata,
  totaldata,
  loading,
  setalldata,
  fullload,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const userdata = useSelector((store) => store.userdata);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [filterdata, setfilterdata] = useState([]);

  const viewbtn = (data) => {
    var newobj = {
      admin_id: userid,
      user_id: data.id,
      message: `${
        userdata[0].first_name.length !== 0
          ? userdata[0].first_name
          : "Super Admin"
      } is viewing the detailed profile information for candidate ${
        data.first_name
      }.`,
      date_time: moment().format(),
      activity: "View Candidate Profile",
    };
    console.log(newobj, "newobj");
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
    navigate("/admincandidateview");
  };

  const calculateProfileCompletion = (profile) => {
    let count = 0;
    if (profile.address !== null) count += 1;
    if (profile.work_preference_info !== null) count += 1;
    if (
      profile.professional_details_info.length !== 0 ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      profile.project_details_info.length !== 0 ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (profile.certificate_info.length !== 0 || profile.no_certificate == true)
      count += 1;
    if (profile.travel_info !== null) count += 1;
    if (profile.education_info.length !== 0) count += 1;
    if (profile.video_resume !== null && profile.video_resume.length !== 0)
      count += 1;

    return count;
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const searchvalue = useCallback(
    debounce((e) => {
      if (e.length !== 0) {
        const matchingSkills = totaldata.filter((skill) =>
          skill.first_name.toLowerCase().includes(e.toLowerCase())
        );
        setalldata(matchingSkills);
        setCurrentPage(1);
      } else {
        setalldata(totaldata);
      }
    }, 500),
    [totaldata, setalldata, setCurrentPage]
  );

  useEffect(() => {
    const filteredData = alldata.filter((data) => data.onboarding_status == 4);
    setfilterdata(filteredData);
  }, [alldata]);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filterdata.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filterdata.length / recordsPerPage);
  return (
    <div>
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder={fullload ? "Please wait loading data..." : "Search..."}
            onChange={(e) => searchvalue(e.target.value)}
            disabled={fullload}
          />
          <img src={search} alt="" />
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>NAME</th>
                <th>LOCATION</th>
                <th>EMPLOYEE ID</th>
                <th>STATUS</th>
                <th>PROFILE COMPLETION</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => {
                    const profileCompletion = calculateProfileCompletion(data);
                    const percent = Math.round((profileCompletion / 8) * 100);
                    return (
                      <tr className="adminTableRow" key={index}>
                        <td>
                          <div className="tableName">
                            {data.profile_picture.length !== 0 ? (
                              <img src={data.profile_picture} alt="" />
                            ) : (
                              <Avatar
                                name={data.first_name}
                                size={30}
                                round="50px"
                              />
                            )}
                            <h1>{data.first_name}</h1>
                          </div>
                        </td>
                        <td>
                          <div className="tableLocation">
                            {data.address !== null ? (
                              <h1>{data.address.country}</h1>
                            ) : (
                              <h1>-</h1>
                            )}
                          </div>
                        </td>
                        <td>
                          <h1>ID{data.employee_id}</h1>
                        </td>
                        <td>
                          {data.dissabled == false ? (
                            data.status === "Reserved" ? (
                              new Date(moment().format("YYYY-MM-DD")) <=
                              new Date(
                                moment(
                                  new Date(
                                    moment(data.block_expiry).format(
                                      "YYYY-MM-DD"
                                    )
                                  )
                                ).format("YYYY-MM-DD")
                              ) ? (
                                <p className="status reserving">
                                  {data.status}
                                </p>
                              ) : data.apprual === true ? (
                                <p className="processingstaus status">
                                  Available For Hire
                                </p>
                              ) : (
                                <p className="processingstaus status">
                                  {data.status}
                                </p>
                              )
                            ) : data.status === "Reject" ? (
                              <p className="status rejectstaus">
                                {data.status}
                              </p>
                            ) : data.status === "Hired" ? (
                              <p className="status hiringActive">
                                {data.status}
                              </p>
                            ) : (data.status === "Success" ||
                                data.status === "Mail send" ||
                                data.status === "Benched") &&
                              data.nottify == true &&
                              data.apprual == true ? (
                              <p className="processingstaus status">
                                Available For Hire
                              </p>
                            ) : (data.status === "Success" ||
                                data.status === "Mail send" ||
                                data.status === "Benched") &&
                              data.nottify === true &&
                              data.apprual === false &&
                              percent === 100 ? (
                              <p className="status hiringActive">Submitted</p>
                            ) : data.nottify !== true ? (
                              <p className="status contracted">Onboarding</p>
                            ) : data.status == "Benched" &&
                              data.nottify == true &&
                              data.apprual == false ? (
                              <p className="status hiringActive">Submitted</p>
                            ) : null
                          ) : (
                            <p className="status rejectstaus">Disabled</p>
                          )}
                        </td>
                        <td>
                          <h1>{percent}%</h1>
                        </td>
                        <td>
                          <button
                            onClick={() => viewbtn(data)}
                            className="viewButton"
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {nPages > 1 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminCandidateProfile;
